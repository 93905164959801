var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-2 mb-4",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Filtrar..."},on:{"keyup":function($event){return _vm.filtro($event, 'title', _vm.filterGlobal)}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)])],1),_c('b-row',{staticClass:"match-height"},[_vm._l((_vm.congresses),function(item){return _c('div',{key:item.id,staticStyle:{"margin":"5px"}},[(_vm.isSpecial || _vm.order.some(function (e) { return e.congress_id === item.id && (e.transaction_status === 3 || e.transaction_status === 4); }) || _vm.ordersGn.some(function (e) { return e.congress_id === item.id && e.transaction_status === 'paid'; }))?_c('a',{attrs:{"href":("/todas-atividades/" + (item.id) + "/" + (item.path_event))}},[_c('b-card',{staticStyle:{"max-width":"20rem","display":"block","margin":"0 auto"},attrs:{"title":item.title,"img-src":(_vm.path + "/storage/congress/image/" + (item.image)),"img-alt":"Image","img-top":"","tag":"article","md":"4"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[(!_vm.isSpecial &&
                !(_vm.order.some(function (e) { return e.congress_id === item.id && (e.transaction_status === 3 || e.transaction_status === 4); }) ||
                  _vm.ordersGn.some(function (e) { return e.congress_id === item.id && e.transaction_status === 'paid'; }))
              )?_c('b-button',{attrs:{"variant":"primary","block":"","hidden":!_vm.isOnCart(item.id)},on:{"click":function($event){_vm.addToCart([{
                course_id: false,
                congress_id: item.id,
                name: item.title,
                amount: 1,
                value: parseFloat(item.amount)
              }])}}},[_vm._v(" Adicionar ao carrinho ")]):_vm._e(),_c('b-button',{attrs:{"variant":"danger","block":"","hidden":_vm.isOnCart(item.id)},on:{"click":function($event){return _vm.removeItem(item)}}},[_vm._v(" Remover pedido ")])],1)],1)],1):_c('a',[_c('b-card',{staticStyle:{"max-width":"20rem","display":"block","margin":"0 auto"},attrs:{"title":item.title,"img-src":(_vm.path + "/storage/congress/image/" + (item.image)),"img-alt":"Image","img-top":"","tag":"article","md":"4"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[(_vm.isSpecial ||
                _vm.order.some(function (e) { return e.congress_id === item.id && (e.transaction_status === 3 || e.transaction_status === 4); }) ||
                _vm.ordersGn.some(function (e) { return e.congress_id === item.id && e.transaction_status === 'paid'; })
              )?_c('b-button',{attrs:{"href":("/todas-atividades/" + (item.id) + "/" + (item.path_event)),"variant":"outline-primary","block":""}},[_vm._v(" Acessar ")]):_c('b-button',{attrs:{"variant":"primary","block":"","hidden":!_vm.isOnCart(item.id)},on:{"click":function($event){_vm.addToCart([{
                course_id: false,
                congress_id: item.id,
                name: item.title,
                amount: 1,
                value: parseFloat(item.amount)
              }])}}},[_vm._v(" Adicionar ao carrinho ")]),_c('b-button',{attrs:{"variant":"danger","block":"","hidden":_vm.isOnCart(item.id)},on:{"click":function($event){return _vm.removeItem(item)}}},[_vm._v(" Remover pedido ")])],1)],1)],1)])}),_c('b-modal',{attrs:{"id":"evt-modal-notification","size":"lg","title":"Eventos para hoje","ok-only":"","no-stacking":""}},[_c('div',[_vm._v(" Você tem alguns eventos para hoje: "),_c('ul',{staticStyle:{"overflow-y":"scroll","height":"220px"},attrs:{"id":"notification"}},_vm._l((_vm.evtList),function(e){return _c('li',{key:e.id},[_vm._v(" "+_vm._s(((e.title) + " - " + (e.start_time.substring(5, 0))))+" "),_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){return _vm.goToUrl(e.congress.path_event, e.slug)}}},[_vm._v(" Entrar ao vivo ")])],1)}),0)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }